<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        <v-col cols="12">Nuevo proceso de importación</v-col>
      </v-card-title>
      <v-card-text>
        <!-- Proceso de importación -->
        <v-row cols="12" class="py-0">
          <v-col cols="7">
            <v-file-input
              ref="file"
              v-model="file"
              :disabled="loading"
              :loading="loading"
              id="fileUpload"
              type="file"
              dense
              label="Excel"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              @change="importExcel"
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-btn
              color="primary"
              class="no-upper-case to-right"
              outlined
              @click="openModalSeeFormatoArchivo()"
            >
              Ver formato
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn outlined @click="closeModalExcel()"> Cerrar </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-container>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="60%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los registros</span
            >
          </v-card-title>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              class="d-flex pt-3 pb-0 justify-content-center"
            >
              <v-switch
                v-model="creaGrupos"
                id="switch1"
                class="mt-1 "
              ></v-switch>
              <label for="switch1" class="mt-2"
                >Crear agrupaciones y grupos si no existen</label
              >
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headersImport"
                :items="excelDataTranslated"
                class="elevation-1"
                :hide-default-footer="true"
                :items-per-page="-1"
                dense
              >
              </v-data-table>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeModalImport" text> Cancelar </v-btn>
            <v-btn
              color="primary"
              class="to-right"
              text
              :loading="loadingProcesarBtn"
              @click="saveProcesoImportacion()"
              :disabled="excelDataTranslated.length < 1"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <FormatoArchivoDialog
      :formatoTitle="formatoTitle"
      :headers="headers"
      :datosFormatoArchivo="datosFormatoArchivo"
      :dataToExport="dataToExport"
      :openFormatoArchivoDialog.sync="showSeeFormatModal"
    />
  </v-card>
</template>

<script>
import { createLogger, mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import FormatoArchivoDialog from "@/components/shared/FormatoArchivoDialog.vue";
import * as XLSX from "xlsx";

export default {
  name: "NuevoProcesoImportacion",
  directives: { mask },
  components: { FormatoArchivoDialog },
  data() {
    return {
      rules: rules,
      loading: false,
      loadingProcesarBtn: false,
      entFinId: null,
      comprobantesAGenerar: [],
      excelData: [],
      excelDataTranslated: [],
      modalExportarExcel: false,
      archivoEnProceso: false,
      file: null,
      isFormSIROValid: false,
      fileProcesoImportacion: null,
      registrosError: [],
      fileName: null,
      formatoTitle: "Formato de archivo",
      headers: [],
      creaGrupos: false,
      datosFormatoArchivo: [],
      showSeeFormatModal: false,
      headersImport: [
        {
          text: "Agrupación",
          value: "agrupacion",
          sortable: false
        },
        {
          text: "Grupo",
          value: "grupo",
          sortable: false
        },
        {
          text: "Entidad efectora",
          value: "entidadEfectora",
          sortable: false
        }
      ],
      dataToExport: {
        formatoName: "Proceso importacion modelo",
        data: [
          {
            ["Agrupacion"]: "Agrupacion prueba (Campo requerido)",
            ["Grupo"]: "	Grupo 1 (Campo requerido)",
            ["Entidad efectora"]: "Código de origen(Campo requerido)"
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions({
      fetchTiposProcesosFormatos: "prestadores/fetchTiposProcesosFormatosById",
      importGruposEntidadesEfectoras:
        "prestadores/importGruposEntidadesEfectoras",
      setAlert: "user/setAlert"
    }),
    closeModalExcel() {
      this.closeModalImport();
      this.$emit("toggleModalNuevoProcesoImportacion");
    },
    closeModalImport() {
      this.clearImportData();
      this.$refs["file"].reset();
    },

    clearImportData() {
      this.excelData = [];
      this.excelDataTranslated = [];
      this.loading = false;
      this.modalExportarExcel = false;
    },

    excelImport(file, rowsToIgnore, importOptions = {}) {
      const workbook = XLSX.read(file, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const options = {
        raw: importOptions.raw || false, // Agrego la opción "raw" para importar valores como cadenas de texto
        headerRow: importOptions.headerRow || 0,
        dataStartRow: importOptions.dataStartRow || 0,
        rowsToIgnore: [...Array(rowsToIgnore).keys()]
      };

      const jsonData = XLSX.utils.sheet_to_json(worksheet, options);

      const headers = getHeaders(worksheet, options.headerRow);
      const filas = jsonData.map(row =>
        headers.reduce((obj, header) => {
          const cellValue = row[header];
          obj[header] = cellValue === undefined ? "" : cellValue.toString(); // Convertir los valores a cadenas de texto
          return obj;
        }, {})
      );

      return { headers, filas };
    },

    async importExcel() {
      if (this.file) {
        try {
          await this.getArchivo(0);
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            this.fileName = this.file.name;
            const excelDataTranslated = this.excelData[0].filas.map(x => ({
              agrupacion: x["Agrupacion"].trim(),
              grupo: x["Grupo"].trim(),
              entidadEfectora: x["Entidad efectora"].trim()
            }));
            this.excelDataTranslated = excelDataTranslated;
            this.loading = false;
            this.modalExportarExcel = true;
          } else {
            setTimeout(async () => {
              await this.importExcel();
            }, 2000);
          }
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.loading = false;
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
        }
      }
    },

    async saveProcesoImportacion() {
      this.loadingProcesarBtn = true;
      const invalidRows = this.excelDataTranslated.filter(
        row => !Number.isInteger(Number(row.entidadEfectora))
      );

      if (invalidRows.length > 0) {
        const errorMessage =
          "La columna 'entidadEfectora' debe contener valores enteros válidos.";
        this.setAlert({ type: "error", message: errorMessage });
        this.loadingProcesarBtn = false;
        return;
      }

      const data = {
        creaGruposInexistentes: this.creaGrupos,
        grupos: this.excelDataTranslated
      };
      const response = await this.importGruposEntidadesEfectoras(data);
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          this.registrosError = response.data.data;
          let tieneErrores = [];
          this.registrosError.forEach(x => {
            if (x.tieneErrores == true) {
              tieneErrores.push(true);
            }
          });
          if (tieneErrores.length > 0) {
            this.setAlert({
              type: "warning",
              message: "No se pudo realizar la importación por tener errores."
            });
          } else {
            this.setAlert({
              type: "success",
              message: "Importación procesada con éxito."
            });
          }
          this.loadingProcesarBtn = false;
          this.closeModalExcel();
          this.exportExcelError();
        }
      }
    },
    async getArchivo(rowsToIgnore) {
      if (!this.archivoEnProceso) {
        this.loading = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);

        if (this.$refs.file.validate()) {
          try {
            const data = await new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = e => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: "array" });

                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];

                const options = {
                  raw: false,
                  headerRow: 0,
                  dataStartRow: 0,
                  rowsToIgnore: [...Array(rowsToIgnore).keys()]
                };
                const jsonData = XLSX.utils.sheet_to_json(worksheet, options);

                const headers =
                  Array.isArray(jsonData) && jsonData.length > 0
                    ? Object.keys(jsonData[options.headerRow])
                    : [];
                const filas =
                  Array.isArray(jsonData) &&
                  jsonData.length > options.dataStartRow
                    ? jsonData
                        .slice(options.dataStartRow)
                        .map((row, rowIndex) =>
                          headers.reduce(
                            (obj, header) => {
                              const cellValue = row[header];
                              obj[header] =
                                cellValue === undefined
                                  ? ""
                                  : cellValue.toString();
                              return obj;
                            },
                            { rowIndex: rowIndex + options.dataStartRow }
                          )
                        )
                    : [];
                resolve({ headers, filas });
              };

              reader.onerror = error => {
                reject("Error al leer el archivo: " + error);
              };

              reader.readAsArrayBuffer(this.file);
            });

            this.excelData = [{ headers: data.headers, filas: data.filas }];
          } catch (error) {
            this.setAlert({ type: "warning", message: error });
          }
          this.loading = false;
          this.modalExportarExcel = true;
        }
      }
    },

    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    exportExcelError() {
      let result = [];
      this.registrosError.forEach(x =>
        result.push({
          ["Agrupacion"]: x.agrupacion,
          ["Grupo"]: x.grupo,
          ["Entidad efectora"]: x.entidadEfectora,
          ["Observaciones"]: x.observacion
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Resumen del proceso de importacion");
    },
    async openModalSeeFormatoArchivo() {
      const formatoProceso = await this.fetchTiposProcesosFormatos(35);
      this.datosFormatoArchivo = formatoProceso;
      this.headers = [
        {
          text: "Nombre campo",
          align: "start",
          sortable: false,
          value: "nombreCampo"
        },
        {
          text: "Longitud máxima",
          align: "start",
          sortable: false,
          value: "longMax"
        },
        {
          text: "Formato",
          value: "formato",
          align: "start",
          sortable: false
        },
        {
          text: "Requerido",
          value: "requerido",
          align: "center",
          sortable: false
        },
        {
          text: "Descripción",
          value: "descripcion",
          align: "start",
          sortable: false
        }
      ];
      this.showSeeFormatModal = true;
    }
  }
};
</script>
