var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"p-0 primary--text"},[_vm._v(" Grupo: ")])],1)],1),_c('v-form',{ref:"filters-form",attrs:{"form":"filters-form","id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isNombreValid),callback:function ($$v) {_vm.isNombreValid=$$v},expression:"isNombreValid"}},[_c('v-container',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{staticClass:"p-0",attrs:{"cols":"8","sm":"8"}},[_c('v-text-field',{attrs:{"type":"text","outlined":"","dense":"","label":"Nombre de grupo","autocomplete":"off","rules":_vm.rules.required.concat(
                [_vm.rules.maxLength(_vm.nombreGrupo, 100)],
                [_vm.rules.requiredTrim(_vm.nombreGrupo)]
              )},model:{value:(_vm.nombreGrupo),callback:function ($$v) {_vm.nombreGrupo=$$v},expression:"nombreGrupo"}})],1)],1)],1)],1),_c('v-card-title',{staticClass:"p-0"},[_c('span',{staticClass:"primary--text"},[_vm._v(" Orígenes ")])]),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchOri),callback:function ($$v) {_vm.searchOri=$$v},expression:"searchOri"}})],1),_c('v-col',{attrs:{"cols":"4","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"disabled":_vm.nombreGrupo === null ||
            _vm.agrupacionSelected === null ||
            _vm.nombreGrupo === '',"loading":_vm.btnIsLoading,"color":"primary"},on:{"click":_vm.openOrigenes}},[_vm._v(" Agregar orígenes ")])],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"oriId","items":_vm.registroSelected,"expanded":_vm.expanded,"loading":_vm.loading,"search":_vm.searchOri,"loading-text":"Cargando datos..."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.deleteOrigen(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Quitar origen de este grupo")])])]}}],null,true)})],1),(_vm.openCargaOrigenes)?_c('v-dialog',{attrs:{"max-width":"90%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalAgrupacion($event)}},model:{value:(_vm.openCargaOrigenes),callback:function ($$v) {_vm.openCargaOrigenes=$$v},expression:"openCargaOrigenes"}},[_c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"p-0"},[_c('span',{staticClass:"primary--text"},[_vm._v(" Búsqueda de orígenes ")])]),_c('v-card',{staticClass:"mx-auto mb-3 pt-0 ",attrs:{"max-width":"100%"}},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"11","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{staticClass:"pr-0 pl-0",attrs:{"cols":"1","align-self":"center","align":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();return _vm.toggleFiltersSelected($event)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}],null,false,348599584)},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{ref:"origen-autocomplete",attrs:{"items":_vm.itemsOrigen,"search-input":_vm.origenesAutocompleteInput,"item-text":"value","item-value":"id","filter":_vm.customFilterOrigen,"outlined":"","hide-no-data":"","hint":"Ingrese código o nombre de origen","clearable":"","return-object":"","dense":"","autocomplete":"off","label":"Origen"},on:{"update:searchInput":function($event){_vm.origenesAutocompleteInput=$event},"update:search-input":function($event){_vm.origenesAutocompleteInput=$event},"change":function($event){return _vm.origenChange(_vm.origenSelected)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.value))])],1)]}},{key:"append",fn:function(){return [(_vm.origenesLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"28","color":"primary"}}):_vm._e()]},proxy:true}],null,false,1566533457),model:{value:(_vm.origenSelected),callback:function ($$v) {_vm.origenSelected=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"origenSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###########'),expression:"'###########'"}],staticClass:"theme--light.v-input input",attrs:{"type":"text","outlined":"","dense":"","label":"CUIT","autocomplete":"off"},model:{value:(_vm.cuit),callback:function ($$v) {_vm.cuit=$$v},expression:"cuit"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsProvincias,"label":"Provincia","item-text":"provNombre","item-value":"provId","return-object":"","outlined":"","clearable":"","dense":""},on:{"change":_vm.setLocalidadesByProvincia},model:{value:(_vm.provinciaSelected),callback:function ($$v) {_vm.provinciaSelected=$$v},expression:"provinciaSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsLocalidades,"label":"Localidades","item-text":"value","item-value":"id","return-object":"","outlined":"","clearable":"","rules":_vm.provinciaSelected
                          ? [
                              _vm.localidadSelected.length != 0 ||
                                'Campo requerido'
                            ]
                          : [],"multiple":"","dense":"","disabled":!_vm.provinciaSelected},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.localidadesToggle()}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.multiselectIconGrupos)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
                          var item = ref.item;
                          var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.value))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.localidadSelected.length.toString() - 1)+" otros) ")]):_vm._e()]}}],null,false,2963849364),model:{value:(_vm.localidadSelected),callback:function ($$v) {_vm.localidadSelected=$$v},expression:"localidadSelected"}})],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"pb-0 pt-1 text-right",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}],null,false,1580343644)},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"1","small":"","type":"submit","form":"filters-form"},on:{"click":function($event){return _vm.applyFilters()}}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersSeleccionOrigenes,"item-key":"oriId","items":_vm.origenesItems,"show-select":"","expanded":_vm.expanded,"items-per-page":20,"loading":_vm.isLoading,"search":_vm.search,"loading-text":"Cargando datos..."},on:{"update:expanded":function($event){_vm.expanded=$event},"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
            var item = ref.item;
            var isSelected = ref.isSelected;
            var select = ref.select;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-simple-checkbox',_vm._g(_vm._b({attrs:{"value":isSelected,"readonly":item.origenUsado,"disabled":item.origenUsado,"color":"primary","ripple":false},on:{"input":function($event){return select($event)}}},'v-simple-checkbox',attrs,false),on))]}}],null,true)},[(item.origenUsado)?_c('span',[_vm._v("Origen asignado a otro grupo dentro de la misma agrupación. No se puede seleccionar")]):_c('span',[_vm._v("Seleccionar origen")])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true}],null,true),model:{value:(_vm.registroSelected),callback:function ($$v) {_vm.registroSelected=$$v},expression:"registroSelected"}},[[(_vm.registroSelected.length > 0)?_c('v-icon',[_vm._v(" mdi-checkbox-marked")]):_vm._e()]],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeOrigenes}},[_vm._v(" Cerrar ")])],1)],1)],1)],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"disabled":_vm.nombreGrupo === null ||
          _vm.agrupacionSelected === null ||
          _vm.nombreGrupo === '',"loading":_vm.btnIsLoading,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1),_c('DeleteDialog',{attrs:{"isLoading":_vm.isLoading,"titleProp":_vm.titleDelete,"maxWidth":'40%',"openDelete":_vm.showDeleteModal},on:{"update:openDelete":function($event){_vm.showDeleteModal=$event},"update:open-delete":function($event){_vm.showDeleteModal=$event},"onDeleteItem":function($event){return _vm.confirmDelete()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }