<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}: </span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model.trim="nombre"
                label="Nombre de agrupación"
                dense
                outlined
                auto-grow
                rows="1"
                :rules="
                  rules.required.concat([
                    rules.requiredTrim(nombre),
                    rules.maxLength(nombre, 100)
                  ])
                "
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="py-0">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mapActions } from "vuex";
export default {
  name: "EditAgrupacion",
  props: {
    agrupacionObj: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rules: rules,
      formEditTitle: "Editar agrupación",
      newTitle: "Nueva agrupación",
      nombre: "",
      maxWidth: "80%",
      isFormValid: false
    };
  },
  created() {
    if (this.agrupacionObj) this.setAgrupacion();
    else this.newAgrupacion();
  },
  methods: {
    ...mapActions({
      saveAgrupacion: "prestadores/saveAgrupacion",
      setAlert: "user/setAlert"
    }),
    setAgrupacion() {
      this.nombre = this.agrupacionObj.value;
    },
    newAgrupacion() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      this.isFormValid = false;
      let data = {
        agrupacionId: this.agrupacionObj ? this.agrupacionObj.id : 0,
        agrupacionNombre: this.nombre
      };
      const res = await this.saveAgrupacion(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito." });
        this.closeModal();
        this.isFormValid = true;
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
