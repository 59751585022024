<template>
  <v-card>
    <v-container>
      <v-card-title class=" p-0">
        <span class=" primary--text">{{ title }}</span>
      </v-card-title>
      <v-card-title class="pl-1 primary--text pb-0">
        Agrupación:
      </v-card-title>
      <v-row>
        <v-col cols="8" class="py-0 pr-1">
          <v-autocomplete
            v-model="agrupacionSelected"
            :items="itemsAgrupacion"
            label="Agrupación"
            item-text="value"
            item-value="id"
            outlined
            clearable
            dense
            :rules="rules.required"
            :disabled="!isNew"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="4" align="end" class="py-0">
          <v-btn
            color="primary"
            @click="openModalAgrupacion()"
            class="to-right"
          >
            Ir a agrupaciones
          </v-btn>
        </v-col>
      </v-row>
      <EditGrupoEntidades
        :agrupacionSelected="agrupacionSelected"
        :editEntidad="editEntidad"
        @closeAndReload="closeAndReload"
      ></EditGrupoEntidades>

      <v-dialog
        v-if="openModalEditAgrupacion"
        v-model="openModalEditAgrupacion"
        max-width="80%"
        @keydown.esc="closeModalAgrupacion"
        persistent
      >
        <EditAgrupaciones
          @closeModalAgrupacion="closeModalAgrupacion"
        ></EditAgrupaciones>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { createLogger, mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditGrupoEntidades from "@/components/modules/prestadores/EditGrupoEntidades.vue";
import EditAgrupaciones from "@/components/modules/prestadores/EditAgrupaciones.vue";

export default {
  name: "EditEntidadesEfectoras",
  directives: { mask },
  components: {
    PageHeader,
    DeleteDialog,
    EditGrupoEntidades,
    EditAgrupaciones
  },
  props: {
    editEntidad: { type: Object }
  },
  directives: { mask },
  data: vm => ({
    search: "",
    nameAgrupacion: "",
    nombreGrupo: "",
    title: "Editar grupo",
    newTitle: "Nuevo grupo",
    rules: rules,
    itemsAgrupacion: [],
    agrupacionSelected: null,
    grupoNombre: "",
    headers: [
      {
        text: "Grupo",
        value: "value",
        sortable: false,
        align: "start"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    openModalEditAgrupacion: false,
    isNew: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    if (this.editEntidad != null) {
      this.setEntidad();
    } else {
      this.newEntidad();
    }
  },
  mounted() {
    this.setSelects();
  },

  methods: {
    ...mapActions({
      getAgrupaciones: "prestadores/getAgrupaciones",
      setAlert: "user/setAlert"
    }),
    setEntidad() {
      this.agrupacionId = this.editEntidad.agrupacionId;
      this.agrupacionSelected = this.agrupacionId;
      this.nameAgrupacion = this.editEntidad.agrupacionNombre;
      this.nombreGrupo = this.editEntidad.grupoNombre;
    },
    async setSelects() {
      const agrupaciones = await this.getAgrupaciones();
      this.itemsAgrupacion = agrupaciones;
    },
    newEntidad() {
      this.title = this.newTitle;
      this.isNew = true;
    },

    openModalAgrupacion() {
      this.openModalEditAgrupacion = true;
    },

    closeAndReload() {
      this.$emit("closeAndReload");
    },
    closeModalAgrupacion() {
      this.openModalEditAgrupacion = false;
      this.setSelects();
    }
  }
};
</script>
