<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <v-row>
          <v-col cols="12" class="pb-0">
            <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
            <PageHeader :title="title" class="pb-1" />
          </v-col>
        </v-row>
        <v-card class="mx-auto mb-3 pt-0" max-width="1200" outlined>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0 pb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="in-out">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                dense
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Agrupoacion -->
                  <v-col cols="3" class="py-0">
                    <v-autocomplete
                      v-model="agrupacionSelected"
                      :items="itemsAgrupacion"
                      @change="setGruposByAgrupaciones"
                      label="Agrupación"
                      item-text="value"
                      item-value="id"
                      return-object
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Grupo -->
                  <v-col cols="4" class="pb-0 py-0">
                    <v-autocomplete
                      class="p-0"
                      v-model="grupoSelected"
                      :items="itemsGrupo"
                      label="Grupo"
                      item-text="value"
                      item-value="id"
                      return-object
                      outlined
                      clearable
                      dense
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="gruposToggle()">
                          <v-list-item-action>
                            <v-icon>
                              {{ multiselectIconGrupos }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Todos </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index === 0">
                          <span>{{ item.value }}</span>
                        </v-chip>
                        <span v-if="index === 1" class="grey--text caption">
                          (+{{ grupoSelected.length.toString() - 1 }}
                          otros)
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!-- Origen -->
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      ref="origen-autocomplete"
                      v-model.trim="origenSelected"
                      :items="itemsOrigen"
                      :search-input.sync="origenesAutocompleteInput"
                      item-text="value"
                      item-value="id"
                      @change="origenChange(origenSelected)"
                      :filter="customFilterOrigen"
                      outlined
                      hide-no-data
                      hint="Ingrese código o nombre de origen"
                      clearable
                      return-object
                      dense
                      autocomplete="off"
                      label="Origen"
                    >
                      <template slot="item" slot-scope="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            item.value
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append>
                        <v-progress-circular
                          indeterminate
                          size="28"
                          v-if="origenesLoading"
                          color="primary"
                        ></v-progress-circular>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12" class="py-0 pt-0" align="right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsGruposEntidadesEfectoras"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            item-key="grupoId"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="6" :md="showHelp ? 5 : 6">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="4" align="end">
                    <v-btn
                      v-if="canImport"
                      color="primary"
                      class="to-right no-upper-case"
                      @click="toggleModalNuevoProcesoImportacion()"
                    >
                      Proceso de importación
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="3"
                    :md="showHelp ? 3 : 2"
                    align="end"
                    v-if="canCreate"
                  >
                    <v-btn
                      color="primary"
                      @click="openModalEditAgrupacion()"
                      class="to-right newGrupo"
                    >
                      Nuevo grupo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <section style="display: inline" v-on="on">
                    <v-icon
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      :disabled="item.origenes.length === 0"
                      @click="verOrigenes(item)"
                    >
                      {{ seeIcon }}
                    </v-icon>
                  </section>
                </template>
                <span v-if="item.origenes.length === 0">No hay orígenes</span>
                <span v-else>Ver orígenes</span>
              </v-tooltip>
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="editPencil"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEditAgrupacion(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar grupo</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteGrupo(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar grupo</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-col cols="12" class="pr-0">
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="to-right py-4"
                v-bind="attrs"
                small
                outlined
                :disabled="itemsGruposEntidadesEfectoras.length == 0"
                @click="exportExcelModelo"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right px-0 mt-3"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="openSeeOrigenes"
      v-if="openSeeOrigenes"
      @keydown.esc="toggleSeeOrigenes"
      max-width="70%"
      persistent
    >
      <v-card>
        <v-card-title class="pl-1">
          <span class="pl-1 primary--text"
            >Orígenes: {{ nombreGrupoInOrigenes }}</span
          >
        </v-card-title>
        <v-data-table
          :items="origenesToSee"
          sort-by="oriId"
          sort-asc
          :search="searchInOrigenes"
          :headers="headersOrigenes"
        >
          <template v-slot:top>
            <v-col cols="10">
              <v-text-field
                v-model="searchInOrigenes"
                :append-icon="searchIcon"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
          </template>
        </v-data-table>
        <v-card-actions class="pr-3">
          <v-spacer></v-spacer>
          <v-btn outlined @click="toggleSeeOrigenes"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalNuevoProcesoImportacion"
      v-if="modalNuevoProcesoImportacion"
      @keydown.esc="toggleModalNuevoProcesoImportacion"
      max-width="80%"
      persistent
    >
      <NuevoProcesoImportacion
        @toggleModalNuevoProcesoImportacion="toggleModalNuevoProcesoImportacion"
      ></NuevoProcesoImportacion>
    </v-dialog>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="80%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditEntidadesEfectoras
        :editEntidad="editEntidad"
        @closeAndReload="closeAndReload"
      ></EditEntidadesEfectoras>
    </v-dialog>
    <DeleteDialog
      :isLoading="isLoading"
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import PageHeader from "@/components/ui/PageHeader";
import Ayuda from "@/components/shared/Ayuda.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import EditEntidadesEfectoras from "./EditEntidadesEfectoras.vue";
import NuevoProcesoImportacion from "@/components/modules/prestadores/NuevoProcesoImportacion.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "GruposEntidadesEfectoras",
  components: {
    PageHeader,
    Ayuda,
    PageHeader,
    DeleteDialog,
    FiltersSelected,
    GoBackBtn,
    EditEntidadesEfectoras,
    NuevoProcesoImportacion
  },
  data: vm => ({
    titleDelete: enums.titles.DELETE_GRUPOS_ENTIDADES_EFECTORAS,
    searchIcon: enums.icons.SEARCH,
    seeIcon: enums.icons.SEE,
    optionCode: enums.webSiteOptions.GRUPOS_ENTIDADES_EFECTORAS,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    routeToGo: "ConfiguracionPrestadores",
    allowedActions: null,
    rules: rules,
    showExpand: false,
    showFilters: true,
    title: enums.titles.GRUPOS_ENTIDADES_EFECTORAS,
    showHelp: false,
    canCreate: false,
    canEdit: false,
    canDelete: false,
    canImport: false,
    showDeleteModal: false,
    search: "",
    isLoading: false,
    itemsGruposEntidadesEfectoras: [],
    headers: [
      {
        text: "Nombre agrupación",
        align: "start",
        value: "agrupacionNombre",
        sortable: false
      },
      {
        text: "Nombre grupo",
        align: "start",
        value: "grupoNombre",
        sortable: false
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    openModalEdit: false,
    editEntidad: {},
    modalNuevoProcesoImportacion: false,
    //filters
    filtersApplied: [],
    isFormValid: false,
    agrupacionSelected: null,
    itemsAgrupacion: [],
    grupoSelected: [],
    itemsGrupo: [],
    origenSelected: null,
    itemsOrigen: [],
    origenesAutocompleteInput: null,
    banderaOrigenSelected: false,
    origenesLoading: false,
    openSeeOrigenes: false,
    origenesToSee: [],
    searchInOrigenes: "",
    headersOrigenes: [
      {
        text: "Código",
        align: "start",
        value: "oriId",
        sortable: false
      },
      {
        text: "Origen",
        align: "start",
        value: "nombre",
        sortable: false
      },
      {
        text: "CUIT",
        align: "start",
        value: "cuit",
        sortable: false
      },
      {
        text: "Domicilio",
        align: "start",
        value: "domicilio",
        sortable: false
      }
    ]
  }),
  computed: {
    multiselectIconGrupos() {
      if (this.selectAllGrupos) {
        return "mdi-close-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    selectAllGrupos() {
      return (
        this.grupoSelected &&
        this.grupoSelected.length === this.itemsGrupo.length
      );
    }
  },
  watch: {
    origenesAutocompleteInput(val) {
      if (val) {
        this.getOrigenesFilter();
      }
    }
  },
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSelects();
    this.applyFilters();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getGruposEntidadesEfectoras: "prestadores/getGruposEntidadesEfectoras",
      deleteGrupoEntidad: "prestadores/deleteGrupoEntidad",
      fetchOrigenesNombreByQuery: "prestadores/fetchOrigenesNombreByQuery",
      getAgrupaciones: "prestadores/getAgrupaciones",
      getGruposByAgrupaciones: "prestadores/getGruposByAgrupaciones",
      setAlert: "user/setAlert"
    }),
    async applyFilters() {
      this.isLoading = true;
      this.showFilters = false;
      this.customizeFiltersApplied();
      const filters = {
        agrupacionId: this.agrupacionSelected?.id ?? null,
        grupoId:
          this.grupoSelected === null ? [] : this.grupoSelected.map(x => x.id),
        origenId:
          this.origenSelected === null || this.origenSelected === undefined
            ? null
            : this.origenSelected.id
      };
      try {
        const response = await this.getGruposEntidadesEfectoras(filters);
        this.itemsGruposEntidadesEfectoras = response;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_GRUPO_ENTIDADES_EFECTORAS:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .EDITAR_GRUPO_ENTIDADES_EFECTORAS:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions
            .ELIMINAR_GRUPO_ENTIDADES_EFECTORAS:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions
            .IMPORTAR_ENTIDADES_EFECTORAS:
            this.canImport = true;
            break;
          default:
            break;
        }
      });
    },
    async setSelects() {
      const agrupaciones = await this.getAgrupaciones();
      this.itemsAgrupacion = agrupaciones;
    },
    async setGruposByAgrupaciones() {
      this.grupoSelected = null;
      if (this.agrupacionSelected) {
        const grupos = await this.getGruposByAgrupaciones(
          this.agrupacionSelected.id
        );
        this.itemsGrupo = grupos;
      } else this.itemsGrupo = [];
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.agrupacionSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "agrupacionSelected",
          label: "Agrupación",
          model: this.agrupacionSelected.value
        });
      }
      if (this.grupoSelected !== null && this.grupoSelected?.length > 0) {
        this.filtersApplied.splice(1, 1, {
          key: "grupoSelected",
          label: "Grupo",
          model:
            this.grupoSelected?.length > 1
              ? this.grupoSelected[0].value +
                ` (+ ${this.grupoSelected?.length - 1} Otros)`
              : this.grupoSelected[0]?.value
        });
      }
      if (this.origenSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "origenSelected",
          label: "Origen",
          model: this.origenSelected.value
        });
      }
      if (
        (!this.agrupacionSelected || this.agrupacionSelected === undefined) &&
        (this.grupoSelected?.length === 0 || !this.grupoSelected) &&
        (!this.origenSelected || this.origenSelected === undefined)
      ) {
        this.filtersApplied.splice(2, 1, {
          key: "Todos",
          label: "Filtros",
          model: " Todos"
        });
      }
    },
    verOrigenes(item) {
      this.origenesToSee = item.origenes;
      this.nombreGrupoInOrigenes = item.grupoNombre;
      this.openSeeOrigenes = true;
    },
    toggleSeeOrigenes() {
      this.searchInOrigenes = "";
      this.openSeeOrigenes = !this.openSeeOrigenes;
    },
    gruposToggle() {
      this.$nextTick(() => {
        if (this.selectAllGrupos) this.grupoSelected = [];
        else this.grupoSelected = this.itemsGrupo;
      });
    },
    customFilterOrigen(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    origenChange(data) {
      this.origenSelected = data;
    },
    getOrigenesFilter() {
      if (this.timerOrigenes) {
        clearTimeout(this.timerOrigenes);
        this.timerOrigenes = null;
      }
      this.timerOrigenes = setTimeout(async () => {
        if (
          this.itemsOrigen?.some(x => x.value == this.origenesAutocompleteInput)
        ) {
          return;
        } else if (!this.banderaOrigenSelected) {
          this.origenesLoading = true;
          const response = await this.fetchOrigenesNombreByQuery({
            input: this.origenesAutocompleteInput
          });
          this.$refs["origen-autocomplete"].cachedItems = [];
          this.itemsOrigen = response;
          this.origenesLoading = false;
        }
      }, 1000);
    },
    resetForm() {
      this.agrupacionSelected = null;
      this.grupoSelected = [];
      this.origenSelected = null;
    },
    deleteGrupo(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.grupoId;
    },
    async confirmDelete() {
      this.isLoading = true;
      try {
        const response = await this.deleteGrupoEntidad(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.applyFilters();
          this.isLoading = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.isLoading = false;
      }
    },
    toggleModalNuevoProcesoImportacion() {
      this.modalNuevoProcesoImportacion = !this.modalNuevoProcesoImportacion;
      this.setSelects();
      this.applyFilters();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    openModalEditAgrupacion(item) {
      this.editEntidad = item;
      this.openModalEdit = true;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.setSelects();
      this.applyFilters();
    },
    exportExcelModelo() {
      let result = [];
      //se deben mostrar los grupos con sus origenes en registros distintos, uno por cada origen
      this.itemsGruposEntidadesEfectoras.forEach(x =>
        x.origenes.forEach(y =>
          result.push({
            ["Agrupación"]: x.agrupacionNombre,
            ["Grupo"]: x.grupoNombre,
            ["Origen"]: y.nombre
          })
        )
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Detalle de grupos de entidades efectoras"
      );
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-upper-case {
  text-transform: none;
}
</style>
