<template>
  <v-card>
    <v-container>
      <v-card-title class=" p-0">
        <span class=" primary--text">{{ title }}</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="agrupaciones"
        class="elevation-1"
        :search="search"
        :loading="isLoading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="search"
                  :append-icon="searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="4" align="end" v-if="canCreate">
                <v-btn color="primary" @click="openModal()" class="to-right">
                  Nueva agrupación
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left v-if="canEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openModal(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar agrupación</span>
          </v-tooltip>
          <v-tooltip left v-if="canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="removeAgrupacion(item.id)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar agrupación</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cerrar </v-btn>
      </v-card-actions>
      <v-dialog
        v-if="openModalEdit"
        v-model="openModalEdit"
        max-width="400px"
        @keydown.esc="closeModal"
        persistent
      >
        <EditAgrupacion
          :agrupacionObj="agrupacionObj"
          @closeAndReload="closeAndReload"
        ></EditAgrupacion>
      </v-dialog>
      <DeleteDialog
        :titleProp="titleDelete"
        :maxWidth="'25%'"
        :isLoading="loadingDeleteBtn"
        :openDelete.sync="showDeleteModal"
        @onDeleteItem="confirmDelete()"
      />
    </v-container>
  </v-card>
</template>
<script>
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditAgrupacion from "@/components/modules/prestadores/EditAgrupacion.vue";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "EditAgrupaciones",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    EditAgrupacion,
    Ayuda
  },
  data() {
    return {
      rules: rules,
      optionCodeAgrupaciones:
        enums.modules.adminSistema.permissions.AGRUPACIONES,
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      title: "Agrupaciones",
      titleDelete: "¿Eliminar agrupación?",
      agrupaciones: [],
      agrupacionObj: {},
      search: "",
      allowedActions: null,
      canCreate: false,
      canDelete: false,
      canEdit: false,
      loadingDeleteBtn: false,
      openModalEdit: false,
      showDeleteModal: false,
      isLoading: false,
      headers: [
        {
          text: "Codigo de agrupación",
          value: "id",
          descripcion: "",
          sortable: false
        },
        {
          text: "Nombre de agrupación",
          value: "value",
          descripcion: "",
          sortable: false
        },
        {
          text: "Acciones",
          value: "actions",
          descripcion: "",
          sortable: false,
          align: "end"
        }
      ]
    };
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadPermissions();
    this.getAgrupacionesEntidades();
  },
  methods: {
    ...mapActions({
      getAgrupaciones: "prestadores/getAgrupaciones",
      deleteAgrupacion: "prestadores/deleteAgrupacion",
      getPermissionsByOptionCode:
        "AdministracionSistema/getPermissionsByOptionCode",
      setAlert: "user/setAlert"
    }),

    async loadPermissions() {
      const resp = await this.getPermissionsByOptionCode(
        this.optionCodeAgrupaciones
      );
      this.allowedActions = resp.data;
      this.setPermisos();
    },
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_AGRUPACION:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_AGRUPACION:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_AGRUPACION:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async getAgrupacionesEntidades() {
      this.isLoading = true;
      const response = await this.getAgrupaciones();
      this.agrupaciones = response;
      this.isLoading = false;
    },
    openModal(item) {
      this.openModalEdit = true;
      this.agrupacionObj = item;
    },
    closeModal() {
      // this.openModalEdit = false;
      this.$emit("closeModalAgrupacion");
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.getAgrupacionesEntidades();
    },
    removeAgrupacion(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.deleteAgrupacion(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.getAgrupacionesEntidades();
          this.loadingDeleteBtn = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadingDeleteBtn = false;
      }
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
