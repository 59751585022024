<template>
  <v-container class="p-0">
    <v-row>
      <v-col cols="12" class="py-0">
        <v-card-title class="p-0 primary--text">
          Grupo:
        </v-card-title>
      </v-col>
    </v-row>

    <v-form
      v-model="isNombreValid"
      ref="filters-form"
      form="filters-form"
      id="filters-form"
      @submit.prevent="saveEdit()"
    >
      <v-container class="pb-0">
        <v-row>
          <v-col cols="8" sm="8" class="p-0">
            <v-text-field
              type="text"
              outlined
              dense
              label="Nombre de grupo"
              v-model="nombreGrupo"
              autocomplete="off"
              :rules="
                rules.required.concat(
                  [rules.maxLength(nombreGrupo, 100)],
                  [rules.requiredTrim(nombreGrupo)]
                )
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-card-title class="p-0">
      <span class="primary--text"> Orígenes </span>
    </v-card-title>
    <v-row>
      <v-col cols="8" class="py-0">
        <v-text-field
          v-model="searchOri"
          :append-icon="searchIcon"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="4" align="end">
        <v-btn
          :disabled="
            nombreGrupo === null ||
              agrupacionSelected === null ||
              nombreGrupo === ''
          "
          :loading="btnIsLoading"
          @click="openOrigenes"
          class="to-right"
          color="primary"
        >
          Agregar orígenes
        </v-btn>
      </v-col>
    </v-row>
    <!-- Nueva tabla -->
    <v-card class="mb-4">
      <v-data-table
        :headers="headers"
        item-key="oriId"
        :items="registroSelected"
        :expanded.sync="expanded"
        :loading="loading"
        class="elevation-1"
        :search="searchOri"
        loading-text="Cargando datos..."
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="deleteOrigen(item)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Quitar origen de este grupo</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <!-- Fin nueva tabla -->

    <!-- Modal cargar origen -->

    <v-dialog
      v-if="openCargaOrigenes"
      v-model="openCargaOrigenes"
      max-width="90%"
      @keydown.esc="closeModalAgrupacion"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title class="p-0">
            <span class="primary--text"> Búsqueda de orígenes </span>
          </v-card-title>
          <v-card class="mx-auto mb-3 pt-0 " max-width="100%">
            <!-- Filtro -->
            <v-container class="pt-0">
              <v-row justify="end">
                <v-col
                  cols="11"
                  align="left"
                  class="pt-0 pb-0"
                  @click="showFilters = true"
                >
                  <FiltersSelected
                    :filters="filtersApplied"
                    v-if="!showFilters"
                  />
                </v-col>
                <v-col
                  cols="1"
                  align-self="center"
                  align="center"
                  class="pr-0 pl-0"
                >
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        class="primary--text"
                        @click.stop="toggleFiltersSelected"
                      >
                        {{ closeFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>{{
                      !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-expand-transition mode="out-in">
                <v-form
                  v-show="showFilters"
                  v-model="isFormValid"
                  ref="filters-form"
                  id="filters-form"
                >
                  <!-- Filtros comunes -->
                  <v-row>
                    <!-- Origen -->
                    <v-col cols="3" class="py-0">
                      <v-autocomplete
                        ref="origen-autocomplete"
                        v-model.trim="origenSelected"
                        :items="itemsOrigen"
                        :search-input.sync="origenesAutocompleteInput"
                        item-text="value"
                        item-value="id"
                        @change="origenChange(origenSelected)"
                        :filter="customFilterOrigen"
                        outlined
                        hide-no-data
                        hint="Ingrese código o nombre de origen"
                        clearable
                        return-object
                        dense
                        autocomplete="off"
                        label="Origen"
                      >
                        <template slot="item" slot-scope="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.value
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                        <template v-slot:append>
                          <v-progress-circular
                            indeterminate
                            size="28"
                            v-if="origenesLoading"
                            color="primary"
                          ></v-progress-circular>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <!-- CUIT -->
                    <v-col cols="3" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        class="theme--light.v-input input"
                        label="CUIT"
                        v-mask="'###########'"
                        v-model="cuit"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Provincia -->
                    <v-col cols="3" class="py-0">
                      <v-autocomplete
                        v-model="provinciaSelected"
                        :items="itemsProvincias"
                        label="Provincia"
                        item-text="provNombre"
                        item-value="provId"
                        @change="setLocalidadesByProvincia"
                        return-object
                        outlined
                        clearable
                        dense
                      >
                      </v-autocomplete>
                    </v-col>
                    <!-- Localidades -->
                    <v-col cols="3" class="py-0">
                      <v-autocomplete
                        v-model="localidadSelected"
                        :items="itemsLocalidades"
                        label="Localidades"
                        item-text="value"
                        item-value="id"
                        return-object
                        outlined
                        clearable
                        :rules="
                          provinciaSelected
                            ? [
                                localidadSelected.length != 0 ||
                                  'Campo requerido'
                              ]
                            : []
                        "
                        multiple
                        dense
                        :disabled="!provinciaSelected"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item ripple @click="localidadesToggle()">
                            <v-list-item-action>
                              <v-icon>
                                {{ multiselectIconGrupos }}
                              </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title> Todos </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="mt-2"></v-divider>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index === 0">
                            <span>{{ item.value }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text caption">
                            (+{{ localidadSelected.length.toString() - 1 }}
                            otros)
                          </span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row justify="end">
                    <v-col cols="12" md="4" sm="6" class="pb-0 pt-1 text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-2"
                            v-bind="attrs"
                            v-on="on"
                            size="28"
                            @click="resetForm"
                          >
                            {{ clearFiltersIcon }}
                          </v-icon>
                        </template>
                        <span>Limpiar filtros</span>
                      </v-tooltip>
                      <v-btn
                        color="primary"
                        :disabled="!isFormValid"
                        elevation="1"
                        small
                        type="submit"
                        form="filters-form"
                        @click="applyFilters()"
                      >
                        Aplicar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expand-transition>
            </v-container>
          </v-card>
          <!-- Fin filtro -->

          <v-data-table
            :headers="headersSeleccionOrigenes"
            item-key="oriId"
            v-model="registroSelected"
            :items="origenesItems"
            show-select
            :expanded.sync="expanded"
            :items-per-page="20"
            :loading="isLoading"
            class="elevation-1"
            :search="search"
            loading-text="Cargando datos..."
            @toggle-select-all="selectAllToggle"
          >
            <template>
              <v-icon v-if="registroSelected.length > 0">
                mdi-checkbox-marked</v-icon
              >
            </template>
            <template
              v-slot:[`item.data-table-select`]="{
                item,
                isSelected,
                select
              }"
            >
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-simple-checkbox
                    :value="isSelected"
                    :readonly="item.origenUsado"
                    :disabled="item.origenUsado"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @input="select($event)"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>
                <span v-if="item.origenUsado"
                  >Origen asignado a otro grupo dentro de la misma agrupación.
                  No se puede seleccionar</span
                >
                <span v-else>Seleccionar origen</span>
              </v-tooltip>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="closeOrigenes"> Cerrar </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Fin modal cargar origen -->
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cancelar </v-btn>
      <v-btn
        :disabled="
          nombreGrupo === null ||
            agrupacionSelected === null ||
            nombreGrupo === ''
        "
        :loading="btnIsLoading"
        @click="save"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-card-actions>

    <DeleteDialog
      :isLoading="isLoading"
      :titleProp="titleDelete"
      :maxWidth="'40%'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import { createLogger, mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import Template from "../../../views/modules/template/template.vue";

export default {
  name: "EditGrupoEntidades",
  directives: { mask },
  components: {
    FiltersSelected,
    DeleteDialog,
    Template
  },
  props: {
    editEntidad: { type: Object },
    agrupacionSelected: { type: Number }
  },
  data: vm => ({
    isFormValid: false,
    isNombreValid: false,
    btnIsLoading: false,
    deleteIcon: enums.icons.DELETE,
    titleDelete: "¿Desea eliminar origen de este grupo?",
    rules: rules,
    itemsProvincias: [],
    itemsLocalidades: [],
    provinciaSelected: null,
    localidadSelected: [],
    searchOri: "",
    nombreGrupo: null,
    idGrupo: null,
    origenesItems: [],
    showFilters: false,
    disabledCount: 0,
    origenes: [],
    itemsOrigen: [],
    selectedIds: [],
    cuit: null,
    expanded: [],
    loading: false,
    isLoading: false,
    idToDelete: null,
    search: "",
    registroSelected: [],
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    origenesLoading: false,
    searchIcon: enums.icons.SEARCH,
    editIcon: enums.icons.EDIT,
    selectedLocalidades: [],
    registroSave: [],
    origenesSave: [],
    showDeleteModal: false,
    // Filtro
    filtersApplied: [],
    origenSelected: null,
    origenesAutocompleteInput: null,
    banderaOrigenSelected: false,
    openCargaOrigenes: false,
    headersSeleccionOrigenes: [
      {
        text: "Código origen",
        value: "oriId",
        align: "start",
        sortable: false
      },
      {
        text: "Nombre de origen",
        value: "nombre",
        sortable: false,
        align: "start"
      },
      {
        text: "CUIT",
        value: "cuit",
        sortable: false,
        align: "start"
      },
      {
        text: "Domicilio",
        value: "domicilio",
        sortable: false,
        align: "start"
      }
    ],
    headers: [
      {
        text: "Código origen",
        value: "oriId",
        align: "start",
        sortable: false
      },
      {
        text: "Nombre de origen",
        value: "nombre",
        sortable: false,
        align: "start"
      },
      {
        text: "CUIT",
        value: "cuit",
        sortable: false,
        align: "start"
      },
      {
        text: "Domicilio",
        value: "domicilio",
        sortable: false,
        align: "start"
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ]
  }),
  computed: {
    multiselectIconGrupos() {
      if (this.selectAllLocalidades) {
        return "mdi-close-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    selectAllLocalidades() {
      return (
        this.localidadSelected &&
        this.localidadSelected.length === this.itemsLocalidades.length
      );
    },
    isAllSelected() {
      return this.selectedIds.length === this.registroSelected.length;
    }
  },
  watch: {
    origenesAutocompleteInput(val) {
      if (val) {
        this.getOrigenesFilter();
      }
    },
    agrupacionSelected() {
      this.applyFilters();
    }
  },
  created() {
    this.setProvincias();
    if (this.editEntidad != null) {
      this.setGrupoEntidad();
    } else {
    }
    this.reloadData();
  },

  methods: {
    ...mapActions({
      getProvincias: "prestadores/getProvincias",
      getLocalidadesByProvId: "prestadores/getLocalidadesByProvId",
      fetchOrigenesNombreByQuery: "prestadores/fetchOrigenesNombreByQuery",
      getOrigenesByFilters: "prestadores/getOrigenesByFilters",
      saveGrupoEntidad: "prestadores/saveGrupoEntidad",
      setAlert: "user/setAlert"
    }),
    setGrupoEntidad() {
      this.agrupacionId = this.editEntidad.agrupacionId;
      this.agrupacionNombre = this.editEntidad.agrupacionNombre;
      this.nombreGrupo = this.editEntidad.grupoNombre;
      this.idGrupo = this.editEntidad.grupoId;
      this.registroSelected = this.editEntidad.origenes;
    },
    async save() {
      this.btnIsLoading = true;
      this.registroSave = [];

      this.registroSelected.map(x => this.registroSave.push(x.oriId));
      const data = {
        grupoOriId: this.idGrupo ? this.idGrupo : 0,
        grupoNombre: this.nombreGrupo,
        agrupacionId: this.agrupacionSelected,
        origenes: this.registroSave
      };
      try {
        const res = await this.saveGrupoEntidad(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.openCargaOrigenes ? this.closeOrigenes() : this.closeModal();
          this.btnIsLoading = false;
          reloadData();
        }
      } catch (error) {
        this.btnIsLoading = false;
      }
    },
    localidadesToggle() {
      this.$nextTick(() => {
        if (this.selectAllLocalidades) this.localidadSelected = [];
        else this.localidadSelected = this.itemsLocalidades;
      });
    },
    toggleFiltersSelected() {
      this.showFilters = !this.showFilters;
      this.customizeFiltersApplied();
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.origenSelected) {
        this.filtersApplied.push({
          key: "origenSelected.id",
          label: "Origen",
          model: this.origenSelected.value
        });
      }

      if (this.cuit) {
        this.filtersApplied.push({
          key: "cuit",
          label: "CUIT",
          model: this.cuit
        });
      }

      if (this.localidadSelected && this.localidadSelected.length > 0) {
        this.filtersApplied.push({
          key: "localidad",
          label: "Localidad",
          model:
            this.localidadSelected[0].value +
            (this.localidadSelected.length > 1
              ? ` (+ ${this.localidadSelected.length - 1} Otros)`
              : "")
        });
      }

      if (
        !this.origenSelected &&
        !this.cuit &&
        (!this.localidadSelected || this.localidadSelected.length === 0)
      ) {
        this.filtersApplied.push({
          key: "todos",
          label: "Filtros",
          model: "Todos"
        });
      }
    },

    deselectAllFiltered() {
      // Obtener los IDs de todos los registros filtrados que no están deshabilitados
      const filteredIds = this.filteredItems
        .filter(item => !item.origenUsado)
        .map(item => item.oriId);

      // Deseleccionar los registros filtrados
      this.registroSelected = this.registroSelected.filter(
        item => !filteredIds.includes(item.oriId)
      );
    },
    selectAllFiltered() {
      // Obtener los IDs de todos los registros filtrados que no están deshabilitados
      const filteredIds = this.filteredItems
        .filter(item => !item.origenUsado)
        .map(item => item.oriId);

      // Obtener los IDs de los registros filtrados seleccionados actualmente
      const selectedIds = this.registroSelected.map(item => item.oriId);

      // Verificar si todos los registros filtrados están seleccionados actualmente
      const allFilteredSelected = filteredIds.every(id =>
        selectedIds.includes(id)
      );

      // Seleccionar o deseleccionar los registros filtrados según corresponda
      if (allFilteredSelected) {
        this.deselectAllFiltered();
      } else {
        this.registroSelected = [
          ...this.registroSelected,
          ...filteredIds
            .filter(id => !selectedIds.includes(id))
            .map(id => this.filteredItems.find(item => item.oriId === id))
        ];
      }
    },
    // Método para deseleccionar todos los registros filtrados
    deselectAllFiltered() {
      // Obtener los IDs de todos los registros filtrados que no están deshabilitados
      const filteredIds = this.filteredItems
        .filter(item => !item.origenUsado)
        .map(item => item.oriId);

      // Deseleccionar los registros filtrados
      this.registroSelected = this.registroSelected.filter(
        item => !filteredIds.includes(item.oriId)
      );
    },

    areAllFilteredSelected() {
      // Obtener los IDs de todos los registros filtrados que no están deshabilitados
      const filteredIds = this.filteredItems
        .filter(item => !item.origenUsado)
        .map(item => item.oriId);

      // Verificar si todos los registros filtrados están seleccionados
      return filteredIds.every(id =>
        this.registroSelected.some(item => item.oriId === id)
      );
    },
    // Método para alternar la selección de todos los registros filtrados
    toggleSelectAll() {
      if (this.isAllSelected) {
        this.deselectAll();
      } else {
        this.selectAll();
      }
    },

    selectAllToggle() {
      this.selectAllFiltered();
    },
    isAllFilteredSelected() {
      // Obtener los IDs de todos los registros filtrados que no están deshabilitados
      const filteredIds = this.filteredItems.map(item => item.oriId);

      // Verificar si todos los registros filtrados están seleccionados
      return filteredIds.every(id =>
        this.registroSelected.some(item => item.oriId === id)
      );
    },

    customFilterOrigen(item, queryText, itemText) {
      return item.id || item.value.indexOf(queryText) > -1;
    },
    origenChange(data) {
      this.origenSelected = data;
    },
    getOrigenesFilter() {
      if (this.timerOrigenes) {
        clearTimeout(this.timerOrigenes);
        this.timerOrigenes = null;
      }
      this.timerOrigenes = setTimeout(async () => {
        if (
          this.itemsOrigen?.some(x => x.value == this.origenesAutocompleteInput)
        ) {
          return;
        } else if (!this.banderaOrigenSelected) {
          this.origenesLoading = true;
          const response = await this.fetchOrigenesNombreByQuery({
            input: this.origenesAutocompleteInput
          });
          this.$refs["origen-autocomplete"].cachedItems = [];
          this.itemsOrigen = response;
          this.origenesLoading = false;
        } else {
          this.origenesLoading = true;
          //toString: para mandar el dato al backend como string
          const response = await this.fetchOrigenesNombreByQuery({
            input: this.infoNuevaActualizacion.preId.toString()
          });
          this.itemsOrigen = response;
          this.origenSelected = response[0].id;
          this.banderaOrigenSelected = false;
          this.origenesLoading = false;
        }
      }, 1000);
    },
    resetForm() {
      this.origenSelected = null;
      this.cuit = null;
      this.localidadSelected = [];
      this.provinciaSelected = null;
    },

    async applyFilters() {
      this.isLoading = true;
      this.toggleFiltersSelected();
      this.customizeFiltersApplied();
      this.selectedLocalidades = [];
      if (this.localidadSelected)
        this.localidadSelected.forEach(x => {
          this.selectedLocalidades.push(x.id);
        });
      const filters = {
        oriId: this.origenSelected == null ? "" : [this.origenSelected.id][0],
        oriCuit: this.cuit,
        oriLocalidad: this.localidadSelected ? this.selectedLocalidades : [],
        grupoOriId: this.idGrupo ? this.idGrupo : null,
        agrupacionId: this.agrupacionSelected
      };
      try {
        const response = await this.getOrigenesByFilters(filters);
        this.origenesItems = response;
        const filteredItems = this.origenesItems.filter(
          item => !item.origenUsado
        );
        this.origenesItems.map(item => {
          if (item.origenUsado) this.disabledCount += 1;
        });
        this.filteredItems = filteredItems;
        this.selectedIds = filteredItems
          .filter(item => this.selectedIds.includes(item.oriId))
          .map(item => item.oriId);
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    async setProvincias() {
      const provincias = await this.getProvincias();
      this.itemsProvincias = provincias;
    },
    async setLocalidadesByProvincia() {
      this.localidadSelected = [];
      if (this.provinciaSelected) {
        const localidades = await this.getLocalidadesByProvId(
          this.provinciaSelected.provId
        );
        this.itemsLocalidades = localidades;
      } else this.itemsLocalidades = [];
    },
    closeModal() {
      this.$emit("closeAndReload");
    },
    openOrigenes() {
      this.applyFilters();
      this.openCargaOrigenes = true;
    },
    closeOrigenes() {
      this.openCargaOrigenes = false;
      this.search = "";
      this.searchOri = "";
      this.resetForm();
    },
    deleteOrigen(item) {
      this.showDeleteModal = true;
      this.idToDelete = item.oriId;
    },
    async confirmDelete() {
      this.registroSave = [];
      this.origenesSave = [];
      this.registroSelected.map(x =>
        x.oriId != this.idToDelete
          ? this.registroSave.push(x) && this.origenesSave.push(x.oriId)
          : ""
      );
      this.registroSelected = this.registroSave;
      const data = {
        grupoOriId: this.idGrupo ? this.idGrupo : 0,
        grupoNombre: this.nombreGrupo,
        agrupacionId: this.agrupacionSelected,
        origenes: this.origenesSave
      };
      this.showDeleteModal = false;
      this.setAlert({ type: "success", message: "Eliminado con éxito." });
    },
    reloadData() {
      this.applyFilters();
      this.registroSelected.sort((a, b) => a.oriId - b.oriId);
    }
  }
};
</script>
